<template>
    <div class="c-common-anonymous-homepage-content-row-2" @click="$emit('click', $event)">
        <ContentScrollFadeIn>
            <PlanSummaryModule
                v-if="!!planRowContent"
                :content="planRowContent"
                class="c-common-anonymous-homepage-content-row-2__plan"
            />
        </ContentScrollFadeIn>
        <ContentScrollFadeIn>
            <div v-if="!isEmpty(threeUpRow)">
                <h4
                    v-if="threeUpRow.headline"
                    class="u-text--center c-common-anonymous-homepage-content-row-2__heading"
                >
                    {{ threeUpRow.headline }}
                </h4>
                <ContentfulContentBlock
                    :contentRows="shapedRows"
                    :contentTitle="contentTitle"
                />
                <div class="o-row">
                    <div class="o-extra-small--12 o-medium--8 o-medium--offset-2">
                        <CarouselModule
                            v-if="shapedColumns"
                            :slides="shapedColumns"
                            :name="'anon-homepage-3up'"
                            class="c-common-anonymous-homepage-content-row-2__carousel"
                        />
                    </div>
                </div>
            </div>
        </ContentScrollFadeIn>
    </div>
</template>

<script>

import { isEmpty } from 'lodash-es';

import { getShapedThreeUpRowColumns, getShapedThreeUpRowRows } from '~coreRoutes/homepage/js/homepage-utils';

import ContentScrollFadeIn from '~coreModules/core/components/ui/ContentScrollFadeIn.vue';
import PlanSummaryModule from '~coreModules/contentful/components/PlanSummaryModule.vue';

import ContentfulContentBlock from '~modules/contentful/components/ContentfulContentBlock.vue';
import CarouselModule from '~modules/contentful/components/CarouselModule.vue';

export default {
    name: 'CommonAnonymousHomepageContentRow2',
    components: {
        ContentScrollFadeIn,
        PlanSummaryModule,
        CarouselModule,
        ContentfulContentBlock,
    },
    props: {
        planRowContent: {
            type: Object,
            required: true,
        },
        threeUpRow: {
            type: Object,
            default: null,
        },
        contentTitle: {
            type: String,
            default: null,
        },
    },
    emits: ['click'],
    data() {
        return {
            isEmpty,
        };
    },
    computed: {
        shapedColumns() {
            const columns = this.threeUpRow?.columns;
            return getShapedThreeUpRowColumns(columns);
        },
        shapedRows() {
            const modules = this.threeUpRow?.columns;
            return getShapedThreeUpRowRows(modules);
        },
    },
};

</script>

<style lang="scss">
.c-common-anonymous-homepage-content-row-2 {
    &__plan {
        margin-bottom: $nu-spacer-8;

        @include breakpoint(medium) {
            margin-bottom: $nu-spacer-12;
        }
    }

    &__heading {
        margin-bottom: $nu-spacer-3;

        @include breakpoint(medium) {
            margin-bottom: $nu-spacer-2;
        }
    }

    &__carousel {
        @include breakpoint(large) {
            display: none;
        }
    }
}
</style>
