<template>
    <BaseCarouselModule v-bind="$props" />
</template>

<script>
import carouselModuleProps from '~coreModules/contentful/js/carouselModuleProps.js';

import BaseCarouselModule from '~coreModules/contentful/components/BaseCarouselModule.vue';

export default {
    name: 'CarouselModule',
    components: {
        BaseCarouselModule,
    },
    mixins: [carouselModuleProps],
};
</script>
