<template>
    <div>
        <TwoUpRowModule
            v-if="twoUpRow"
            :content="twoUpRow"
            @click="$emit('click', $event)"
        />
        <ContentScrollFadeIn>
            <HomepageRecTray
                v-if="recTray"
                :id="id"
                :recommendationTrayComponent="recommendationTray"
                :recTray="recTray"
                :contentModuleId="recTrayContentModuleId"
                :contentTitle="contentTitle"
            />
        </ContentScrollFadeIn>
    </div>
</template>

<script>
import { markRaw, defineAsyncComponent } from 'vue';

import { COMMON_HOME_MODULE_IDS } from '~routes/homepage/js/homepage-constants';

import TwoUpRowModule from '~coreModules/contentful/components/TwoUpRowModule.vue';
import ContentScrollFadeIn from '~coreModules/core/components/ui/ContentScrollFadeIn.vue';
import HomepageRecTray from '~coreRoutes/homepage/components/HomepageRecTray.vue';

export default {
    name: 'CommonAnonymousHomepageContentRow1',
    components: {
        TwoUpRowModule,
        ContentScrollFadeIn,
        HomepageRecTray,
    },
    props: {
        id: {
            type: String,
            default: null,
        },
        twoUpRow: {
            type: Object,
            default: null,
        },
        recTray: {
            type: Object,
            default: null,
        },
        contentTitle: {
            type: String,
            default: null,
        },
    },
    emits: ['click'],
    data() {
        return {
            recommendationTray: markRaw(
                defineAsyncComponent(
                    () => import(
                        /* webpackChunkName: "catalogTray" */
                        '~modules/core/components/ui/CatalogTray.vue'),
                ),
            ),
            recTrayContentModuleId: COMMON_HOME_MODULE_IDS.recTrayRow,
        };
    },
};
</script>

<style lang="scss">
</style>
