import { isEmpty } from 'lodash-es';

import { EDITORIAL_TEXT_BODY_STYLES } from '~coreModules/contentful/js/contentful-constants';

/**
 * Returns an array of shaped columns for a contentful carousel module
 * @param {Array} content - the array of columns
 * @returns {Array} - the shaped array of columns
 */
export function getShapedThreeUpRowColumns(content) {
    if (isEmpty(content)) {
        return null;
    }

    return content.map(column => ({
        title: column.headline,
        backgroundImage: {
            smallImage: { url: column.illustration },
            imageQuality: 0,
        },
        editorialText: {
            bodyStyle: EDITORIAL_TEXT_BODY_STYLES.caption,
            body: column.body,
            headline: column.headline,
            headlineStyle: 'heading-5',
            textAlignment: 'center',
            textColor: 'black',
            textPosition: 'below',
            title: column.headline,
        },
    }));
}

/**
 * Returns an array of shaped rows for a contentful content block
 * @param {Array} content - the array of modules
 * @returns {Array} - the shaped array of rows
 */
export function getShapedThreeUpRowRows(content) {
    if (isEmpty(content)) {
        return null;
    }

    return [{
        leftOffset: 1,
        rightOffset: 1,
        showOnBreakpoint: 'Large',
        gutters: '32',
        noStacking: false,
        fullBleedLarge: false,
        modules: content.map(module => ({
            moduleType: 'image',
            title: module.headline,
            backgroundImage: {
                smallImage: { url: module.illustration },
                imageQuality: 0,
            },
            editorialText: {
                body: module.body,
                bodyStyle: EDITORIAL_TEXT_BODY_STYLES.caption,
                headline: module.headline,
                headlineStyle: 'heading-5',
                textAlignment: 'center',
                textColor: 'black',
                textPosition: 'below',
                title: module.headline,
            },
        })),
    }];
}
