<template>
    <div class="o-row home-carousel" @click="$emit('click', $event)">
        <div class="o-extra-small--12 o-medium--10 o-medium--offset-1 o-large--12 o-large--offset-0">
            <div class="home-carousel__text">
                <label v-if="!!moduleContent.label" class="home-carousel__section-label">
                    {{ moduleContent.label }}
                </label>
                <h3 v-if="!!moduleContent.headline" class="home-carousel__section-heading">
                    {{ moduleContent.headline }}
                </h3>
                <p v-if="!!moduleContent.body">
                    {{ moduleContent.body }}
                </p>
            </div>

            <BaseCarouselModule
                v-if="shapedColumns"
                :slides="shapedColumns"
                :lightButtons="lightButtons"
                :name="'anon-homepage-3up'"
                :largeBreakpointItemsPerSlide="3"
            />
        </div>
    </div>
</template>

<script>

import { isEmpty, get } from 'lodash-es';

import BaseCarouselModule from '~coreModules/contentful/components/BaseCarouselModule.vue';

export default {
    name: 'HomepageCarousel',
    components: {
        BaseCarouselModule,
    },
    props: {
        moduleContent: {
            type: Object,
            required: true,
        },
        lightButtons: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['click'],
    computed: {
        shapedColumns() {
            const columns = get(this.moduleContent, 'columns');

            if (isEmpty(columns)) {
                return null;
            }

            return columns.map(column => ({
                ...column,
                title: column.ctaTitle,
                backgroundImage: {
                    smallImage: { url: column.imageUrl },
                    imageQuality: 0,
                },
                buttons: {
                    buttonSize: 'small',
                    ctaPosition: 'bottom',
                    ctaAlignment: 'center',
                    ctaStyle: 'primary-light',
                    destinations: [{
                        linkPath: column.destination,
                        linkTitle: column.ctaTitle,
                        openInNewWindow: false,
                    }],
                },
                destination: {
                    linkPath: column.destination,
                    openInNewWindow: false,
                },
            }));
        },
    },
};
</script>

<style lang="scss">
    .home-carousel {
        &__text {
            text-align: center;
            margin-bottom: $nu-spacer-5;
        }

        &__section-heading {
            margin-bottom: $nu-spacer-1pt5;
        }
    }
</style>
