<template>
    <div class="c-home-hero u-text--center" @click="$emit('click', $event)">
        <BaseVideo
            v-if="heroVideo && !videoHasError"
            ref="video"
            muted
            autoplay
            :src="heroVideo"
            class="c-home-hero__hero-media"
            @error="handleVideoError"
            @suspend="handleVideoSuspend"
        />
        <img
            v-else-if="heroImage"
            :src="heroImage"
            alt=""
            class="c-home-hero__hero-media"
        >
        <div class="c-home-hero__copy-container o-row">
            <div class="o-extra-small--12 o-medium--8 o-medium--offset-2 u-text--center">
                <label v-if="!!content.label" class="c-anonymous-home__section-label u-text--white">
                    {{ content.label }}
                </label>
                <h1
                    v-if="!!content.headline && !!content.headlineColor"
                    class="c-home-hero__headline"
                    :class="{
                        [`c-home-hero__headline--${content.headlineColor}`]: true,
                    }"
                >
                    {{ content.headline }}
                </h1>
                <p
                    v-if="!!content.subheading && !!content.subheadingColor"
                    class="c-home-hero__body-copy"
                    :class="{
                        [`c-home-hero__body-copy--${content.subheadingColor}`]: true,
                    }"
                >
                    {{ content.subheading }}
                </p>
                <BaseLink
                    v-if="!!content.ctaUrl && !!content.ctaTitle && !!content.ctaButtonColor"
                    :to="content.ctaUrl"
                >
                    <BaseButton
                        :colorVariant="colorVariant"
                        :variant="buttonVariant"
                        class="c-home-hero__button"
                        :class="{
                            [`c-home-hero__button--${content.ctaButtonColor}`]: true,
                        }"
                    >
                        {{ content.ctaTitle }}
                    </BaseButton>
                </BaseLink>
            </div>
        </div>
    </div>
</template>

<script>

import { get } from 'lodash-es';

import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';
import BaseVideo from '~coreModules/core/components/ui/BaseVideo.vue';

export default {
    name: 'HomepageHero',
    components: {
        BaseButton,
        BaseVideo,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    emits: ['click'],
    data() {
        return {
            isMounted: false,
            videoHasError: false,
        };
    },
    computed: {
        heroImage() {
            if (this.isMounted) {
                const images = get(this.content, 'heroImageUrls', { large: '', small: '' });
                return this.$mediaQueries.isSmallish ? images.small : images.large;
            }
            return '';
        },
        heroVideo() {
            const video = get(this, 'content.video');

            if (this.isMounted && video) {
                const { large, small } = video;

                try {
                    const url = this.$mediaQueries.isSmallish ? small : large;

                    // validate URLs; if not valid, only the hero image will be displayed
                    return new URL(url).href;
                } catch (_) {
                    if (!!large || !!small) {
                        // if both URLs aren't supplied, assume user is intentionally leaving blank to show only image
                        this.$logger.debugError('Invalid URL supplied for homepage anonymous hero videos');
                    }
                    return null;
                }
            }

            return null;
        },
        buttonVariant() {
            const { ctaButtonColor = '' } = this.content;
            const styleParts = ctaButtonColor.split('-');
            return styleParts[0];
        },
        colorVariant() {
            const { ctaButtonColor = '' } = this.content;
            const styleParts = ctaButtonColor.split('-');
            return styleParts[1];
        },
    },
    mounted() {
        this.isMounted = true;
    },
    methods: {
        handleVideoError() {
            this.videoHasError = true;

            this.$logger.debugError('Anonymous homepage hero video failed to load');
        },
        handleVideoSuspend() {
            const { videoNode } = this.$refs.video;

            if (videoNode) {
                videoNode.play().catch(() => {
                    this.videoHasError = true;

                    this.$logger.debugError('Anonymous homepage hero video failed to load,' +
                        'potentially due client\'s battery saving mode');
                });
            }
        },
    },
};
</script>

<style lang="scss">
    .c-home-hero {
        $this: &;

        height: 52vh;
        min-height: 500px;
        width: 100%;
        position: relative;

        @include breakpoint(medium) {
            height: 58vh;
        }

        &__hero-media {
            object-fit: cover;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        &__copy-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }

        &__headline {
            @include title-3;
            margin-bottom: $nu-spacer-1;

            @include breakpoint(medium) {
                @include title-1;
                margin-bottom: $nu-spacer-1pt5;
            }

            &--primary {
                color: $nu-primary;
            }

            &--secondary {
                color: $nu-secondary;
            }

            &--white {
                color: $nu-white;
            }

            &--black {
                color: $nu-black;
            }
         }

         &__body-copy {
            @include body-copy;

            @include breakpoint(medium) {
                margin-bottom: $nu-spacer-4;
            }

            &--primary {
                color: $nu-primary;
            }

            &--secondary {
                color: $nu-secondary;
            }

            &--white {
                color: $nu-white;
            }

            &--black {
                color: $nu-black;
            }
         }

        &__button {
            position: absolute;
            bottom: $nu-spacer-4;
            left: 0;
            right: 0;
            margin: auto;

            @include breakpoint(medium) {
                position: static;
                margin: 0;
            }

            &--primary-dark {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                    border-color: $nu-primary !important;
                    color: $nu-primary !important;
                }
            }

            &--primary-light {
                &:hover,
                &:focus,
                &:active {
                    background-color: $transparent !important;
                    border-color: $nu-white !important;
                    color: $nu-white !important;
                }
            }

            &--secondary-dark {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                }
            }

            &--secondary-light {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                    border-color: $nu-primary !important;
                    color: $nu-primary !important;
                }
            }
        }
    }
</style>
