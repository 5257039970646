<template>
    <div class="c-common-anonymous-homepage">
        <HomepageHero
            v-if="!!content.heroRow"
            :content="content.heroRow"
            @click="onModuleClick($event, moduleIds.anonymousHero)"
        />

        <AnonymousHomepageContentRow1
            v-if="!!content.twoUpRow || !!content.recTrayRow"
            :id="moduleIds.contentRow1"
            :twoUpRow="content.twoUpRow"
            :recTray="content.recTrayRow"
            :contentTitle="contentTitle"
            class="c-common-anonymous-homepage__section c-common-anonymous-homepage__section--row1"
            @click="onModuleClick($event, moduleIds.contentRow1)"
        />

        <BrandsRowModule
            v-if="!!content.brandsRow"
            :content="content.brandsRow"
            class="c-common-anonymous-homepage__section"
            @click="onModuleClick($event, moduleIds.brands)"
        />

        <AnonymousHomepageContentRow2
            v-if="!!content.planRow || !!content.threeUpRow"
            :planRowContent="content.planRow"
            :threeUpRow="content.threeUpRow"
            :contentTitle="contentTitle"
            class="c-common-anonymous-homepage__section c-common-anonymous-homepage__section--row2"
            @click="onModuleClick($event, moduleIds.contentRow2)"
        />

        <ContentScrollFadeIn>
            <HomepageCarousel
                v-if="!!content.sliderRow"
                :moduleContent="content.sliderRow"
                :lightButtons="false"
                class="c-common-anonymous-homepage__section"
            />
        </ContentScrollFadeIn>

        <HomepageOneUpContent
            v-if="!!content.oneUpRow"
            :content="content.oneUpRow"
            @click="onModuleClick($event, moduleIds.thrift)"
        />

        <HomepageSocialGridRow
            v-if="!!content.socialGridRow"
            :content="content.socialGridRow"
            @click="onModuleClick($event, moduleIds.socialGrid)"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { getClickEventHref } from '~coreModules/core/js/utils';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { CONTENTFUL_CONTENT_CLICKED } from '~coreModules/core/js/global-event-constants';
import { COMMON_HOME_MODULE_IDS } from '~routes/homepage/js/homepage-constants';

import ContentScrollFadeIn from '~coreModules/core/components/ui/ContentScrollFadeIn.vue';
import BrandsRowModule from '~coreModules/contentful/components/BrandsRowModule.vue';

import HomepageHero from '~coreRoutes/homepage/components/HomepageHero.vue';
import HomepageOneUpContent from '~coreRoutes/homepage/components/HomepageOneUpContent.vue';
import HomepageCarousel from '~coreRoutes/homepage/components/HomepageCarousel.vue';
import HomepageSocialGridRow from '~coreRoutes/homepage/components/HomepageSocialGridRow.vue';

import AnonymousHomepageContentRow1 from '~routes/homepage/components/CommonAnonymousHomepageContentRow1.vue';
import AnonymousHomepageContentRow2 from '~routes/homepage/components/CommonAnonymousHomepageContentRow2.vue';

export default {
    name: 'CommonAnonymousHomepage',
    components: {
        ContentScrollFadeIn,
        HomepageHero,
        BrandsRowModule,
        AnonymousHomepageContentRow1,
        AnonymousHomepageContentRow2,
        HomepageCarousel,
        HomepageOneUpContent,
        HomepageSocialGridRow,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
        contentTitle: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            moduleIds: COMMON_HOME_MODULE_IDS,
        };
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        onModuleClick(event, contentModuleId) {
            const clickEventHref = getClickEventHref(event);

            this.trackGlobalEvent({
                type: CONTENTFUL_CONTENT_CLICKED,
                data: {
                    destination: clickEventHref,
                    contentModuleId,
                    contentTitle: this.contentTitle,
                },
            });
        },
    },
};
</script>

<style lang="scss">
.c-common-anonymous-homepage {
    text-align: left;
    width: 100%;
    background-color: $nu-white;

    &__hero-container {
        height: calc(100vh - var(--headerOffset));
    }

    &__section-label,
    &__section-heading {
        display: block;
    }

    &__section-label {
        margin-bottom: $nu-spacer-0pt5;
    }

    &__section-heading {
        margin-bottom: $nu-spacer-1pt5;
    }

    &__section-body {
        margin-bottom: $nu-spacer-4;
    }

    &__section {
        padding: $nu-spacer-12 $nu-spacer-2;

        @include breakpoint(medium) {
            padding: $nu-spacer-16 $nu-spacer-5;
        }

        @include breakpoint(large) {
            padding: $nu-spacer-16 $nu-spacer-8;
        }

        &--row1,
        &--row2 {
            background-color: $nu-secondary;
        }
    }
}
</style>
