<template>
    <div class="c-home-social-grid-row">
        <div class="o-row">
            <div
                :class="[
                    'o-extra-small--12',
                    'o-medium--10',
                    'o-medium--offset-1',
                    'o-large--12',
                    'o-large--offset-0',
                ]"
            >
                <div class="c-home-social-grid-row__header">
                    <p v-if="!!content.label" class="o-text--label u-spacer--1">
                        {{ content.label }}
                    </p>
                    <h2
                        v-if="!!content.headline"
                        class="o-text--heading-1 u-spacer--1pt5"
                    >
                        {{ content.headline }}
                    </h2>
                    <p v-if="!!content.body" class="c-home-social-grid-row__body">
                        {{ content.body }}
                    </p>
                </div>
                <SocialGridModule
                    name="social-grid-anon-homepage"
                    :gridImages="content.gridImages"
                    :link="content.link"
                    paddingBottom="0px"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SocialGridModule from '~coreModules/contentful/components/SocialGridModule.vue';

export default {
    name: 'HomepageSocialGridRow',
    components: {
        SocialGridModule,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
};

</script>

<style lang="scss">
.c-home-social-grid-row {
    background-color: $nu-white;
    padding: $nu-spacer-10 0;

    @include breakpoint(large) {
        padding: $nu-spacer-16 0;
    }

    &__header {
        text-align: center;
    }

    &__body {
        margin-bottom: $nu-spacer-5;

        @include breakpoint(medium) {
            margin-bottom: $nu-spacer-8;
        }
    }
}
</style>
