<template>
    <div class="c-home-one-up">
        <div class="c-home-one-up__container o-row">
            <div
                v-if="!!content.image"
                class="c-home-one-up__image o-extra-small--12 o-small--10 o-small--offset-1 o-medium--5"
            >
                <img alt="" :src="content.image">
            </div>
            <div
                :class="[
                    'o-extra-small--12',
                    'o-small--10',
                    'o-small--offset-1',
                    'o-medium--4',
                    'o-medium--offset-7',
                ]"
            >
                <p v-if="!!content.label" class="o-text--label u-spacer--1pt5">
                    {{ content.label }}
                </p>
                <div class="c-home-one-up__logo-container u-spacer--1pt5">
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        alt="Logo"
                        class="c-home-one-up__logo"
                        v-html="dynamicSVGRequire(nuulyLogoSrc)"
                    ></div>
                    <!-- eslint-enable -->
                </div>
                <p v-if="!!content.body" class="u-spacer--4">
                    {{ content.body }}
                </p>
                <BaseLink v-if="!!content.ctaTitle && !!content.ctaUrl" :to="content.ctaUrl">
                    <BaseButton>
                        {{ content.ctaTitle }}
                    </BaseButton>
                </BaseLink>
            </div>
        </div>
    </div>
</template>

<script>
import { dynamicSVGRequire } from '~coreModules/core/js/svg-utils';

import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

export default {
    name: 'HomepageOneUpContent',
    components: {
        BaseButton,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
        nuulyLogoSrc: {
            type: String,
            default: 'logo--rent.svg',
        },
    },
    data() {
        return {
            dynamicSVGRequire,
        };
    },
};

</script>

<style lang="scss">
.c-home-one-up {
    padding: $nu-spacer-10 0;
    background-color: $nu-secondary;

    @include breakpoint(large) {
        padding: $nu-spacer-16 0;
    }

    &__container {
        align-items: center;
    }

    &__logo {
        width: 167px;
        height: 32px;
        fill: $nu-primary;

        svg {
            width: 100%;
            max-width: 100%;
        }
    }

    &__image {
        margin-bottom: $nu-spacer-4;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }
    }
}
</style>
